@import "../../../less/style.less";

.pmv-normale {
  color: green;
}

.pmv-degradato {
  color: yellow;
}

.pmv-guasto {
  color: red;
}

.pmv-sconosciuto {
  color: gray;
}

.pmv-offline {
  color: skyblue;
}

.pmv-panel-detail {
  min-height: 170px !important;
  max-height: 175px !important;
  border: 2px solid @global-grey-light;
}

.lampeggiante-giallo,
.lampeggiante-grigio {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin: 15px;
}

.lampeggiante-giallo {
  background-color: yellow;
}

.lampeggiante-grigio {
  background-color: gray;
}

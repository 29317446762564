html,
body,
#root {
  height: 100%;
  width: 100%;
}

.App {
  text-align: center;
  font-family: Calibri;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: rgb(26, 26, 26);
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  background-color: rgb(26, 26, 26);
}

::-webkit-scrollbar-thumb {
  border-radius: 0.2rem;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0.3);
  background-color: rgb(88, 88, 88);
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib,
.mapboxgl-ctrl-attrib-inner,
.mapboxgl-ctrl-logo,
.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
  display: none !important;
}

/* Chrome, Safari, Edge, Opera */
.page-number-input::-webkit-outer-spin-button,
.page-number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.page-number-input[type="number"] {
  -moz-appearance: textfield;
}
